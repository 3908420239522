import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './website/header/header.component';
import { FooterComponent } from './website/footer/footer.component';
import { DefaultComponent } from './website/pages/default/default.component';
import { AuthService } from './auth/auth.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTPCommonModule } from './common.module';
import { NgxElectronModule } from 'ngx-electron';
import { ConfirmDialogComponent } from './modals/confirm-dialog/confirm-dialog.component';
import { MsgDialogComponent } from './modals/msg-dialog/msg-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
	ConfirmDialogComponent,
	MsgDialogComponent,
    HeaderComponent,
    FooterComponent,
    DefaultComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.fireConfig),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    AngularFirePerformanceModule, //performance
    BrowserAnimationsModule,
    BrowserModule,
    NgxElectronModule,
    AppRoutingModule,

    HTPCommonModule
  ],
  providers: [
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
