export const environment = {
  production: false,
  fireConfig: {  
    apiKey: "AIzaSyD0gXhQ2xwKrRhc9uDfbLU4CNPtuN01GV8",
    authDomain: "lunar-caster-600.firebaseapp.com",
    databaseURL: "https://lunar-caster-600.firebaseio.com",
    projectId: "lunar-caster-600",
    storageBucket: "lunar-caster-600.appspot.com",
    messagingSenderId: "560989415330",
    appId: "1:560989415330:web:8c1f998960b03100bb34fb"
  }
};