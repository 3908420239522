import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './website/pages/default/default.component';
import { AdminPanelComponent } from './admin/admin.component';


const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: DefaultComponent
	},
	{
		path: 'admin',
		component: AdminPanelComponent,
		loadChildren: () => import('./admin/admin.module')
			.then(m => m.AdminModule), data: {
				redirect: '/admin'
			}
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
