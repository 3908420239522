import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { switchMap } from 'rxjs/operators';
import { User } from '../models/user';
import { User as FireUser } from 'firebase';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
 
  authUser$ = new BehaviorSubject<FireUser>(null);
  eventAuthError = new BehaviorSubject<string>("");
  eventAuthError$ = this.eventAuthError.asObservable();
  headers = new HttpHeaders({'Content-Type': 'application/json'});
  isAdmin: Observable<boolean>;
  user$ = new BehaviorSubject<User>(null);

  constructor(
    private _afAuth: AngularFireAuth,
    private _db: AngularFirestore,
    private _router: Router,
  ) {
    this._afAuth.authState.subscribe(usr => {
      if (!usr) return;
      this.authUser$.next(usr);
      // this._router.navigate(['/admin']);
      // let u = this._db.doc<User>(`users/${usr.uid}`).valueChanges();
      // u.subscribe(x => {
      //   console.log(x);
      //   if (x) {
      //     // this.isAdmin = new Observable(obs => obs.next(x.role_id == 99));
      //     // this.membership$.next(x.membership);
      //     // x.id = usr.uid;
      //     this.user$.next(x);
      //   }
      // });
      // this.authUser$.next(usr);
    });
  }

  public get currentUserValue(): User {
    return this.user$.value;
  }

  checkAuthorization(user: User, role_id: number): boolean {
    if (!user) return false;
    else if (user.role_id == role_id) return true;
    return false;
  }

  login(email: string, password: string) {
    return this._afAuth.signInWithEmailAndPassword(email, password)
      .catch(error => {
        this.eventAuthError.next(error);
        return error;
      })
      .then(userCredential => {
        if (userCredential)
          this._router.navigate(['/admin']);
        return userCredential;
      });
  }

  createUser(user, accountType) {
    return this._afAuth.createUserWithEmailAndPassword(user.email, user.password)
      .then(userCredential => {
        userCredential.user.updateProfile({
          displayName: user.firstName + ' ' + user.lastName
        });
        this._db.doc(`users/${userCredential.user.uid}`).set({
          active: false,
          email: user.email,
          email_confirmed: false,
          firstname: user.firstName,
          lastname: user.lastName,
          role_id: 1,
          membership: {
            type: accountType,
            status: 'unpaid',
            token: ''
          }
        }, { merge: true });
        return userCredential;
      })
      .catch(error => {
        this.eventAuthError.next(error);
      });
  }

  // changePassword(data) {
  //   return this._http.post("https://us-central1-rpinvestments-6daf9.cloudfunctions.net/changePassword", data, {headers: this.headers}).toPromise();
  // }

  // sendResetPassword(data) {
  //   return this._http.post("https://us-central1-rpinvestments-6daf9.cloudfunctions.net/resetPasswordLink", data, {headers: this.headers}).toPromise();
  // }

  // sendEmailConfirmation(data) {
  //   return this._http.post("https://us-central1-rpinvestments-6daf9.cloudfunctions.net/sendEmailConfirmation", data, {headers: this.headers}).toPromise();
  // }

  logout(redirect = null) {
    this.authUser$.next(null);
    this.user$.next(null);
    this.isAdmin = new Observable(obs => obs.next(false));
    this._afAuth.signOut();
    if (redirect) this._router.navigateByUrl(redirect);
  }
}
