import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ElectronService } from 'ngx-electron';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private _electronService: ElectronService,
    private _router: Router
  ) { 
    if (this._electronService.isElectronApp)
      this._router.navigate(['/admin']);
  }

  ngOnInit() {
  }
}
