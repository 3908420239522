import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private _document: Document,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
      this._document.body.style.background = '#FFF';
  }
  ngOnDestroy() {
      this._document.body.style.background = '#166d9a';
  }


}
