import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CKEditorModule } from 'ckeditor4-angular';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
    declarations: [
    ],
    imports: [
        CKEditorModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
		MatDialogModule,
		MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
		MatIconModule,
        MatMenuModule,
		MatNativeDateModule,
        MatSelectModule,
        MatSlideToggleModule,
		MatProgressBarModule,
		MatTableModule,
		MatTabsModule,
        MatListModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        FullCalendarModule,
        RouterModule,
        OwlDateTimeModule, 
        OwlNativeDateTimeModule,
    ],
    exports: [
        CKEditorModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDialogModule,
		MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatMenuModule,
		MatNativeDateModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        MatTableModule,
		MatTabsModule,
        MatListModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        FullCalendarModule,
        RouterModule,
        OwlDateTimeModule, 
        OwlNativeDateTimeModule,
    ]
})
export class HTPCommonModule { }