import { Component, Inject } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { User } from 'firebase';
import { DOCUMENT } from '@angular/common';
import { ElectronService } from 'ngx-electron';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { fader, slideInOut } from './animations';

@Component({
    selector: 'app-admin-panel',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    animations: [
        slideInOut
    ]
})
export class AdminPanelComponent {

    $user = new BehaviorSubject<User>(null);
    menuOpen = false;
    curmenu;

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _authService: AuthService,
        public electronService: ElectronService,
        private _router: Router
    ) {
    }

    ngOnInit() {
        this._authService.authUser$.subscribe(usr => this.$user.next(usr));
    }

    ngAfterViewInit() {
        this.checkURL(this._router.url);
        this._router.events.subscribe(d => {
            if (!(d instanceof NavigationEnd)) return;
            this.checkURL(d.url);
        });
    }

    checkURL(url) {
        if (url.includes('airport')) this.curmenu = 'airport';
        else if (url.includes('bookings')) this.curmenu = 'bookings';
        else if (url.includes('company')) this.curmenu = 'company';
        else if (url.includes('custom')) this.curmenu = 'custom';
        else if (url.includes('dashboard') || url == '/admin') this.curmenu = 'dashboard';
        else if (url.includes('invoicing')) this.curmenu = 'invoicing';
        else if (url.includes('tours')) this.curmenu = 'tours';
        else if (url.includes('user')) this.curmenu = 'user';

        if (!url.includes('login')) {
            this._document.body.style.background = '#166d9a';
            this._document.body.className = "";
        }
        else if (url.includes('login')) {
            this._document.body.style.background = null;
            this._document.body.className = "login";
        }
    }

    ngOnDestroy() {
        this._document.body.style.background = '#FFF';
    }

    exit() {
        this.electronService.remote.BrowserWindow.getFocusedWindow().close();
    }

    logout() {
        this._authService.logout('/admin/login');
    }

    maximize() {
        this.electronService.remote.BrowserWindow.getFocusedWindow().isMaximized() ?
            this.electronService.remote.BrowserWindow.getFocusedWindow().restore() :
            this.electronService.remote.BrowserWindow.getFocusedWindow().maximize();
    }
    
    minimize() {
        this.electronService.remote.BrowserWindow.getFocusedWindow().minimize();
    }
    
    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }
}