<header class="text-right bg-white d-flex mat-elevation-z1 noselect no-print">
    <div *ngIf="$user | async">        
        <div class="theme-dark">
            <button 
                mat-flat-button
                style="width: 80px; background: #166d9a; border-radius: 0;"   
                #trig1="matMenuTrigger"
                (menuOpened)="menuOpen = true"
                (menuClosed)="menuOpen = false"
                [matMenuTriggerFor]="mainMenu">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
        <mat-menu 
            #mainMenu="matMenu" 
            class="mat-elevation-z1">
            <button 
                mat-menu-item 
                [routerLink]="['/admin', 'dashboard']" 
                [ngClass]="{'active': curmenu == 'dashboard'}">
                <mat-icon>dashboard</mat-icon> Dashboard
            </button>        
            <button 
				mat-menu-item 
				[routerLink]="['/admin', 'users', 'history']"
                [ngClass]="{'active': curmenu == 'user'}">
                <mat-icon>people</mat-icon> Users
            </button>
            <button 
				mat-menu-item 
				[routerLink]="['/admin', 'tours', 'history']"
                [ngClass]="{'active': curmenu == 'tours'}">
                <mat-icon>card_travel</mat-icon> Tours
            </button>
            <button 
				mat-menu-item 
				[routerLink]="['/admin', 'bookings', 'history']"
                [ngClass]="{'active': curmenu == 'bookings'}">
                <mat-icon>today</mat-icon> Tour Dates
            </button>
            <button 
				mat-menu-item 
				[routerLink]="['/admin', 'company', 'history']"
                [ngClass]="{'active': curmenu == 'company'}">
                <mat-icon>location_city</mat-icon> Companies
            </button>
            <button 
				mat-menu-item 
				[routerLink]="['/admin', 'airport-service', 'history']"
                [ngClass]="{'active': curmenu == 'airport'}">
                <mat-icon>airplanemode_active</mat-icon> Airport Services
            </button>
            <button 
				mat-menu-item 
				[routerLink]="['/admin', 'custom-service', 'history']"
                [ngClass]="{'active': curmenu == 'custom'}">
                <mat-icon>pending_actions</mat-icon> Custom Services
            </button>
            <button 
				mat-menu-item 
				[routerLink]="['/admin', 'invoicing', 'history']"
                [ngClass]="{'active': curmenu == 'invoicing'}">
                <mat-icon>attach_money</mat-icon> Invoicing
            </button>
        </mat-menu>
    </div>
    <div style="-webkit-app-region: drag; flex-grow: 100" class="text-left pl-2">
        <img src="./assets/logo.svg" height="36px">
    </div>
    <button *ngIf="$user | async" mat-button color="primary" (click)="logout()"><mat-icon>logout</mat-icon>Logout</button>
    <div class="theme-dark">
        <button 
            mat-button 
            (click)="minimize()" 
            *ngIf="electronService.isElectronApp"><mat-icon>minimize</mat-icon></button>
        <button 
            mat-button 
            (click)="maximize()"
            *ngIf="electronService.isElectronApp"><mat-icon style="transform: rotate(180deg);">filter_none</mat-icon></button>
        <button 
            mat-button 
            (click)="exit()"
            *ngIf="electronService.isElectronApp"><mat-icon>clear</mat-icon></button>
    </div>
</header>

<div class="ah-100 noselect" [@routeAnimations]="prepareRoute(outlet)">
    <div class="bg-shade" [ngClass]="{'bg-shade-open': menuOpen}"></div>
    <router-outlet #outlet="outlet"></router-outlet>
</div>