import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: 'msg-dialog',
	templateUrl: 'msg-dialog.component.html',
})
export class MsgDialogComponent {

	showOk = true;

	constructor(
		public dialogRef: MatDialogRef<MsgDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
			this.showOk = data.showOk != null ? data.showOk : true;
	}

	close(): void {
		this.dialogRef.close();
	}
}
