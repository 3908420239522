<!-- <app-header></app-header>
<app-footer></app-footer> -->
<img src="./assets/logo.svg">
<div class="sm-container">
	<h3>Website under development</h3>
	<p>While the website is being developed to provide you a better experience, please feel free to contact us at anytime:</p>
	<p>email:<br />info@htptours.com</p>
	<p>phone or whatsapp:<br />+1 905-761-9080</p>
	<br />
	<p>Sincerely,<br />
		Julie, Humberto &amp; the entire HTP Family</p>
</div>